import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { meetTypeAtom, partnerMeetStateAtom } from '../../core/config/atoms';

import thank from '../../assets/image/thank.jpg';

export default function PanelThankYou({ partner }) {

    const [, setMeetType] = useRecoilState(meetTypeAtom);
    const [, setMeetState] = useRecoilState(partnerMeetStateAtom);

    const navigate = useNavigate();

    const gotoBack = () => {
        setMeetType('');
        setMeetState(0);
        navigate('/home/main');
    }

    const gotoWeb = () => {
        window.open(partner.companyWeb, '_blank', 'noopener,noreferrer');
    }

    return (
        <div>
            <div className='h-screen flex flex-col items-center justify-center'>
                <img src={thank} alt='Thank You Image' className='mt-10' />
                <div className='w-[90%] xl:w-[600px] flex flex-col xl:flex-row items-center justify-between mt-20'>
                    <div className="intro-y text-white text-base text-center font-serif bg-prime hover:bg-primeLight cursor-pointer rounded-md w-60 my-2 mx-auto py-2" onClick={() => { gotoBack() }}>Go Back</div>
                    <div className="intro-y text-white text-base text-center font-serif bg-prime hover:bg-primeLight cursor-pointer rounded-md w-60 my-2 mx-auto py-2" onClick={() => { gotoWeb() }}>Visit Website</div>
                </div>
            </div>
        </div>
    )
}