import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, phoneAtom } from '../core/config/atoms';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../services/serviceAuth';

import bgLogin from '../assets/image/bglogin.png';
import bgmLogin from '../assets/image/bgmLogin.png';

export default function PageAuth() {


    const [authStatus] = useRecoilState(authAtom);
    const [, setPhone] = useRecoilState(phoneAtom);

    const phoneRef = useRef('');

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        const number = phoneRef.current.value;
        if (/^\d{10}$/.test(number)) {
            let body = {
                'phone': number
            };
            authService.userPhoneVerify(body, '').then((res) => {
                if (res.statuscode == 201) {
                    let body = {
                        "phone": number,
                        "token": ""
                    }
                    authService.userLogin(body, '').then((res) => {
                        if (res.status) {
                            toast.success("OTP Sent to registred mobile number!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            setPhone(number);
                            gotoOTP();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                } else {
                    setSubmit(false);
                    toast.error("User account does not exist try creating new one!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            })
        } else {
            toast.error('Enter valid phone number !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            setSubmit(false);
        }
    }

    const gotoOTP = () => {
        navigate('/verify');
    }

    const gotoRegister = () => {
        navigate('/register');
    }

    useEffect(() => {
        if (authStatus === 'verified') {
            navigate('/home/main');
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 pt-6 pb-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <img src={bgmLogin} alt="brand logo" className="login background w-[54%] lg:hidden" />
                                <h1 className="text-2xl text-black font-serif font-medium">Welcome back!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to see you again!</h2>
                                <form className="mt-8" onSubmit={handleSubmit}>
                                    <div className="flex flex-col">
                                        <label className="text-sm text-gray-900 font-serif">Phone number
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="off" placeholder="i.e. 123456789" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" ref={phoneRef} />
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && "LOGIN"}
                                    </button>
                                    <div className='flex items-center mt-4'>
                                        <div className='w-[80px] h-[1px] bg-gray-600'></div>
                                        <p className='text-sm text-center mx-auto font-medium'>Don't have an account</p>
                                        <div className='w-[80px] h-[1px] bg-gray-600'></div>
                                    </div>
                                    <button type="button" className="mt-4 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" onClick={() => {
                                        gotoRegister();
                                    }}>
                                        REGISTER
                                    </button>
                                </form>
                                <div id="captcha"></div>
                                <div className="flex-grow"></div>
                                <p className="text-sm text-gray-400 font-serif text-center mt-16">&copy; 2024 India Signing Hands Private Limited.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <div className="w-full h-[64vh] bg-prime flex lg:hidden items-start lg:items-center justify-center"></div>
                        <div className="w-full h-full hidden lg:flex items-start lg:items-center justify-center">
                            <img src={bgLogin} alt="brand logo" className="login background" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}