import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from '../../core/config/atoms';

import { useRazorpay } from "react-razorpay";

import { ChevronDown } from 'lucide-react';
import { ScrollArea } from "@/components/ui/scroll-area";

import { motion } from "framer-motion";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { toast } from 'react-toastify';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import TopBar from '../../core/widgets/ui/TopBar';

import ServicePack from 'services/servicePack';
import ServiceTransaction from 'services/serviceTransaction';

export default function PanelSubscription(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const { Razorpay } = useRazorpay();

    const [transactions, setTransactions] = useState([]);
    const [packs, setPacks] = useState([]);
    const [wallet, setWallet] = useState({});

    const [filter, setFilter] = useState({ 'userId': userData.userId });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [isSub, setIsSub] = useState(true);

    const navigate = useNavigate();
    const packService = new ServicePack();
    const transactionService = new ServiceTransaction();

    const gotoLink = () => {
        navigate(`/home/setting`);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const buyPackage = (pack) => {
        let body = {
            "uid": userData.userId,
            "wkey": userData.walletKey,
            "amount": 1, //pack.rate,
            "pid": pack._id,
            "pcode": pack.code
        };
        transactionService.addTransaction(body, token).then((res) => {
            var options = {
                key: "rzp_live_rPFcI9MHXgltQY",
                amount: 1 * 100, //pack.rate
                currency: "INR",
                name: "India Signing Hands Private Limited",
                description: "ISH Call Sign Package Buy - " + pack.title,
                order_id: res.data.paymentData.oid,
                handler: (response) => {
                    let bodyA = {
                        "uid": userData.userId,
                        "tid": res.data.trnId,
                        "wkey": userData.walletKey,
                        "pid": pack._id,
                        "status": "paid"
                    };
                    transactionService.updateTransaction(bodyA, token).then((res) => {
                        if (res.status) {
                            toast.success('Payment successful!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                },
                prefill: {
                    name: `${userData.user.fname}${userData.user.mname === "NA" ? "" : ` ${userData.user.mname}`}${userData.user.lname === "NA" ? "" : ` ${userData.user.lname}`}`,
                    email: userData.user.email,
                    contact: userData.user.phone,
                },
                theme: {
                    color: "#2C499E",
                },
            };

            const rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
                if (response.error.reason == "payment_cancelled") {
                    let bodyA = {
                        "uid": userData.userId,
                        "tid": res.data.trnId,
                        "wkey": userData.walletKey,
                        "status": "cancel"
                    };
                    transactionService.updateTransaction(bodyA, token).then((res) => {
                        if (res.status) {
                            toast.error('Payment cancelled!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                } else {
                    let bodyA = {
                        "uid": userData.userId,
                        "tid": res.data.trnId,
                        "wkey": userData.walletKey,
                        "status": "fail"
                    };
                    transactionService.updateTransaction(bodyA, token).then((res) => {
                        if (res.status) {
                            toast.error('Payment failed!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            });

            rzp1.open();
        });
    }

    const getExportItems = () => {
        let items = [];
        transactions.forEach((item) => {
            items.push({
                trnId: item.trnId,
                trnType: item.trnType,
                amount: item.amount,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "transactions");
        XLSX.writeFile(workbook, "transaction.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = () => {
        let headers = ['trnId,trnType,amount,status,createdAt'];
        let usersCsv = transactions.reduce((tran, item) => {
            const { trnId, trnType, amount, status, createdAt } = item
            tran.push([trnId, trnType, amount, status, createdAt].join(','))
            return tran
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'transaction.csv',
            fileType: 'text/csv',
        });
    }

    const getDetails = (code) => {
        let pack = packs.filter((a) => a.code == code)[0];
        return `${pack.title} (${code})`;
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'prog':
                return " bg-yellow-100 text-yellow-800";
            case 'paid':
                return " bg-green-100 text-green-800";
            case 'fail':
            case 'cancel':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getStsTitle = (status) => {
        switch (status) {
            case 'prog':
                return "Processing";
            case 'paid':
                return "Paid";
            case 'fail':
                return "Failed";
            case 'cancel':
                return "Canceled";
            default:
                return "NA";
        }
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await transactionService.getAllTransaction(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTransactions(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const formatMinutesToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const finalSeconds = remainingSeconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedFinalSeconds = String(finalSeconds).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedFinalSeconds}`;
    }

    const loadData = () => {
        (async function () {
            var res = await packService.getAllPack({ 'country': userData.country }, token);
            if (res['status']) {
                const sortedData = res.data.slice().sort((a, b) => {
                    return a.index - b.index;
                });
                setPacks(sortedData);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }

            var resW = await transactionService.getWalletById({ 'wKey': userData.walletKey }, token);
            if (resW.data !== null) {
                setWallet(resW['data']);
            }

            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await transactionService.getAllTransaction(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTransactions(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <>
            {
                isOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-screen md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full lg:w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>Buy Packages</h1>
                                    <span onClick={() => {
                                        setIsOpen(false);
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <hr />
                                <ul className='mt-4 space-y-4'>
                                    {
                                        packs.length !== 0 && packs.map((pack) => {
                                            return <li>
                                                <div className='flex items-center justify-between border p-2 rounded'>
                                                    <div>
                                                        <h1 className='text-base font-bold'>{pack.title} - {pack.code}</h1>
                                                        <p className='text-sm'><b>{pack.value}</b> Min / <b>{pack.rate}</b> ₹</p>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { buyPackage(pack) }}>
                                                            BUY
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <ScrollArea>
                <div className="w-full min-h-screen py-3 px-3 mb-[60px] md:mb-0">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div className="intro-y flex items-center justify-between h-10">
                                <h2 className="text-2xl font-medium truncate ml-2 text-prime hidden lg:block">
                                    <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Manage Subscription
                                </h2>
                                <h2 className="text-2xl font-medium truncate ml-2 text-prime lg:hidden">
                                    <motion.i whileHover={{ scale: 1.2 }} className="las la-arrow-left cursor-pointer" onClick={() => { gotoLink() }}></motion.i> Subscription
                                </h2>
                                <TopBar />
                            </div>
                            <hr className='mt-2' />
                            <div className='max-w-[860px] mx-auto border mt-4 rounded'>
                                <div className='w-full p-4 flex items-center justify-between'>
                                    <div>
                                        <h1 className='text-xl text-prime font-bold'>Wallet balance</h1>
                                        <p className='text-base'>Total wallet balance is : {Object.keys(wallet).length !== 0 ? formatMinutesToTime(wallet.balence) : "00:00"} Min</p>
                                    </div>
                                    <div className='hover:bg-gray-50 cursor-pointer' onClick={() => { setIsSub(!isSub) }}>
                                        <ChevronDown />
                                    </div>
                                </div>
                                {
                                    isSub && <>
                                        <hr />
                                        {
                                            Object.keys(wallet).length === 0 && <div className='w-full p-4 flex items-center justify-between'>
                                                <div>
                                                    <h1 className='text-xl text-prime font-bold'>NA</h1>
                                                </div>
                                                <div>
                                                    <button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { setIsOpen(true) }}>
                                                        BUY
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        <ul className='space-y-4 py-2'>
                                            {
                                                Object.keys(wallet).length !== 0 && wallet.packBalence.map((pack) => {
                                                    return <li className='w-full px-4 flex items-center justify-between'>
                                                        <div>
                                                            <h1 className='text-base font-bold'>{pack.title}</h1>
                                                            <h2 className='text-sm text-prime font-medium'>Current Balance : {formatMinutesToTime(pack.value)} Min</h2>
                                                        </div>
                                                        <div>
                                                            <button type="button" className="inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight" onClick={() => { setIsOpen(true) }}>
                                                                BUY
                                                            </button>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </>
                                }
                            </div>
                            <div className="col-span-12 mt-6 md:mt-16 px-2">
                                <div className="w-full md:hidden">
                                    <div className='mb-4 flex my-2 items-center'>
                                        <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by transaction id" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                    </div>
                                    <div className='space-y-3'>
                                        {
                                            transactions.length !== 0 && transactions.map((tans) => {
                                                return <div className="w-full overflow-hidden shadow-md rounded-md border" key={tans._id}>
                                                    <div className="px-4 py-2 whitespace-nowrap cursor-pointer flex items-center justify-between">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div className='flex items-center text-blue-600 uppercase'>
                                                                {tans.trnId}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="px-4 py-2 whitespace-nowrap">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                Package Details: {getDetails(tans.paymentData.pcode)}
                                                            </div>
                                                            <div className="text-sm font-medium text-green-800">
                                                                Amount : ₹ {tans.amount}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className='my-2' />
                                                    <div className="px-4 mb-4 whitespace-nowrap flex items-center justify-between">
                                                        <div className="text-sm text-blue-600">
                                                            {Moment(tans.createdAt).format('DD, MMM yyyy')}
                                                        </div>
                                                        <p className='font-sans font-medium'>
                                                            <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(tans.status)}`}>
                                                                {getStsTitle(tans.status)}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {
                                        transactions.length === 0 && <div className="cursor-pointer bg-gray-100 w-full">
                                            <div className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-blue-600 text-center">
                                                    No data found.
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='flex py-2 px-4 items-center bg-blue-50 mt-8'>
                                        <p className='text-sm font-serif'>Show rows per page</p>
                                        <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <div className='flex-grow'></div>
                                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                    </div>
                                </div>
                                <div className="w-full shadow-md rounded-md border hidden md:table">
                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                        <thead className="bg-gray-50">
                                            <tr className=''>
                                                <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                                    <div className='w-full grid grid-cols-12 gap-2'>
                                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                            <div className='w-full flex col-span-12 items-center'>
                                                                <div className='w-[80px]'>
                                                                    <p>Search :</p>
                                                                </div>
                                                                <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by transaction id" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                            </div>
                                                        </form>
                                                        <div className='col-span-1 flex items-center'>
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger>
                                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <DropdownMenuItem>
                                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                        exportToExcel();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </DropdownMenuItem>
                                                                                <DropdownMenuItem>
                                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                        exportToCsv();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </DropdownMenuItem>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Transaction ID
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Package Details
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Amount
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Transaction Date
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                transactions.length !== 0 && transactions.map((tran) => {
                                                    return <tr className="hover:bg-gray-100" key={tran._id}>
                                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div className='flex items-center text-prime uppercase'>
                                                                    {tran.trnId}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div>{getDetails(tran.paymentData.pcode)}</div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div>₹ {tran.amount}</div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm font-bold text-gray-900">
                                                                <div>{Moment(tran.createdAt).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(tran.status)}`}>
                                                                {getStsTitle(tran.status)}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            {
                                                transactions.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="5">
                                                        <div className="text-sm text-red-600 text-center">
                                                            No data found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table >
                                    <div className='flex py-2 px-4 items-center bg-blue-50'>
                                        <p className='text-sm font-serif'>Show rows per page</p>
                                        <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <div className='flex-grow'></div>
                                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                    </div>
                                </div >
                            </div >
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
