import React, { useState } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import ReactStars from "react-rating-stars-component";

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

import ServiceTicket from 'services/serviceTickets';

export default function PaneReview({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [rating, setRating] = useState(0);

    const [submit, setSubmit] = useState(false);

    const ticketService = new ServiceTicket();

    const formVSchema = Yup.object().shape({});

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            feedback: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "tid": props.id,
                "name": props.name,
                "rating": rating,
                "feedback": values.feedback,
                "type": "customer",
            }
            ticketService.addReviewByRoomId(body, token).then((res) => {
                props.onSubmit();
                setSubmit(false);
            });
        }
    })

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    return (
        <Dialog open={props.status} onClose={() => { props.onSubmit(); }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Submit Review for Interpreter &nbsp;
                    </h3>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit}>
                    <div className="w-full">
                        <div className="flex items-center justify-center">
                            <ReactStars count={5} isHalf={true} onChange={ratingChanged} value={rating} size={54} activeColor="#ffd700" />
                        </div>
                    </div>
                    <div className="w-full mt-4">
                        <div className="">
                            <label className="block text-xs font-medium text-gray-700 font-mono">Feedback</label>
                            <textarea id="feedback" value={values.feedback} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Your feedback"></textarea>
                            {(errors.feedback && touched.feedback) && <p className='text-xs text-red-400 mt-1'>{errors.feedback}</p>}
                        </div>
                    </div>
                    <div className="px-2 py-3 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "SUBMIT"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
