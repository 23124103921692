import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServicePack {
    lookupService = new APIServiceLookUp();

    async getAllPack(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}pack/getAllPackByFilter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}