import React, { useEffect, useState } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { partnerAtom, partnerMeetStateAtom } from '../../core/config/atoms';

import parse from 'html-react-parser';
import Lottie from 'lottie-react';

import PanelDev from 'panels/dev/PanelDev';
import PanelSelectDept from './meet/PanelSelectDept';
import PanelInitMeet from './meet/PanelInitMeet';
import PanelMeet from './meet/PanelMeet';
import PanelThankYou from './meet/PanelThankYou';

import ServiceProfiles from 'services/serviceProfiles';

import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelMeetEntery() {

    const [meetState] = useRecoilState(partnerMeetStateAtom);
    const [partnerLink] = useRecoilState(partnerAtom);

    const [data, setData] = useState({});

    const [headHtml, setHeadHtml] = useState("");
    const [footHtml, setFootHtml] = useState("");

    const [loading, setLoading] = useState(true);

    const profileService = new ServiceProfiles();

    const loadData = () => {
        (async function () {
            let body = {
                "link": partnerLink
            };
            var res = await profileService.checkLink(body, {});
            if (res.data) {
                let subs = res.data.subscription;
                setData(res.data);
                if (subs.pageData === "") {
                    setHeadHtml("<div className='w-full min-h-[16vh] bg-blue-100'></div>");
                    setFootHtml("<div className='w-full min-h-[40vh] bg-blue-100'></div>");
                } else {
                    const payload = JSON.parse(subs.pageData);
                    setHeadHtml(payload.head);
                    setFootHtml(payload.foot);
                    injectCSS(payload.css);
                }
            }
            setLoading(false);
        })();
    }

    const injectCSS = (cssString) => {
        const style = document.createElement('style');
        style.innerHTML = cssString;
        document.head.appendChild(style);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full xl:h-screen">
            {parse(headHtml)}
            {
                loading && <div className='w-full h-[84vh] md:h-[74vh] px-[2%] md:px-[10%] bg-gray-100 flex items-center justify-center'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {!loading && <div className='w-full min-h-[84vh] md:min-h-[74vh] px-[2%] md:px-[10%] bg-gray-100 flex items-center justify-center'>
                {
                    (() => {
                        switch (meetState) {
                            case 1:
                                return (<PanelSelectDept partner={data} />);
                            case 2:
                                return (<PanelInitMeet partner={data} />);
                            case 3:
                                return (<PanelMeet partner={data} />);
                            case 4:
                                return (<PanelThankYou partner={data} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>}
            {parse(footHtml)}
        </ScrollArea>
    )
}
