import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom, tokenAtom, walletAtom } from '../../../core/config/atoms';

import { toast } from 'react-toastify';

import { Bell } from 'lucide-react';

import { Menu, Transition } from '@headlessui/react';
import { motion } from "framer-motion";

import socket from '../../../core/config/socket';

export default function TopBar() {
    const [userData, setUserData] = useRecoilState(userAtom);
    const [, setAuthStatus] = useRecoilState(authAtom);
    const [, setToken] = useRecoilState(tokenAtom);
    const [wallet, setWallet] = useRecoilState(walletAtom);

    const [isLogin, setIsLogin] = useState(false);

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        localStorage.clear();
        navigate('/');
    }

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const formatMinutesToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const finalSeconds = remainingSeconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedFinalSeconds = String(finalSeconds).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    }

    const updateWallet = () => {
        setIsLogin(true);
        socket.emit('login', { userData });
        socket.on(userData.walletKey, (payload) => {
            setUserData(payload.user);
            setWallet(payload.wallet);
            setToken(payload.token);
        });
    }

    const gotoLink = () => {
        navigate('/home/subscription');
    }

    useEffect(() => {
        if (!isLogin) {
            updateWallet();
        }
    }, [isLogin]);

    return (
        <div className='relative z-50 flex items-center space-x-2 lg:space-x-4'>
            <div className='text-xs lg:text-sm text-white bg-prime px-2 lg:px-3 py-1 rounded-full flex cursor-pointer' onClick={() => { gotoLink() }}><span className='hidden lg:block'>Balance:&nbsp;</span>{formatMinutesToTime(wallet.balence)} Min</div>
            <Menu>
                <Menu.Button>
                    <motion.div whileTap={{ scale: 0.90 }}>
                        <div className='w-9 h-9 rounded-full border-2 border-prime overflow-clip cursor-pointer'>
                            {
                                userData.profileMedia !== undefined && <img src={Object.keys(userData.profileMedia).length === 0 ? userData.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png" : userData.profileMedia.fileurl} alt="Avatar Icon" />
                            }
                            {
                                userData.profileMedia === undefined && <img src={userData.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png"} alt="Avatar Icon" />
                            }
                        </div>
                    </motion.div>
                </Menu.Button>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0">
                    <Menu.Items>
                        <div
                            className='bg-white shadow-md rounded-md absolute' style={{ top: '48px', left: '-140px' }}>
                            <div>
                                <ul className='flex flex-col items-start p-2'>
                                    <Menu.Item>
                                        <li className='w-40 cursor-pointer hover:bg-blue-100 p-2 rounded-md' onClick={() => { changeMainMenu('setting') }}>
                                            <div className='flex items-center text-base'>
                                                <i className="las la-cog text-lg"></i>
                                                <p className='text-sm font-serif ml-2'>Settings</p>
                                            </div>
                                        </li>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <li className='w-40 cursor-pointer hover:bg-red-100 p-2 rounded-md' onClick={() => { logout() }}>
                                            <div className='flex items-center text-base'>
                                                <i className="las la-sign-out-alt text-lg"></i>
                                                <p className='text-sm font-serif ml-2'>Logout</p>
                                            </div>
                                        </li>
                                    </Menu.Item>
                                </ul>
                            </div>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <Bell size={22} className='mr-2 cursor-pointer hidden lg:block' onClick={() => {
                toast.info('No new notification !', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }} />
        </div>
    )
}
