import React from 'react';

import { useRecoilState } from 'recoil';
import { partnerMeetStateAtom } from '../core/config/atoms';

import PanelDev from 'panels/dev/PanelDev';

import PanelMeetMain from 'panels/partnermeet/PanelMeetMain';
import PanelMeetEntery from 'panels/partnermeet/PanelMeetEntery';

export default function LayoutMeet() {

    const [meetState] = useRecoilState(partnerMeetStateAtom);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                (() => {
                    switch (meetState) {
                        case 0:
                            return (<PanelMeetMain />);
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            return (<PanelMeetEntery />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}
