import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom } from '../core/config/atoms';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutTicket from '../layouts/LayoutTicket';
import LayoutSetting from '../layouts/LayoutSetting';

import mqtt from 'mqtt';

export default function PageHome() {

    const [userData] = useRecoilState(userAtom);

    const { page } = useParams();

    const loadData = () => {
        (async function () {
            // var options = {
            //     port: 1883,
            //     clientId: 'auto_admin',
            //     username: "ishService",
            //     password: "ishMqtt@2024",
            //     keepalive: 60,
            //     reconnectPeriod: 1000,
            //     protocolVersion: 5.0,
            //     clean: true,
            //     encoding: 'utf8'
            // };

            // const mqttClient = mqtt.connect('mqtt://127.0.0.1', options);

            // mqttClient.on('connect', () => {
            //     console.log('Connected to MQTT broker');
            //     // mqttClient.subscribe('test/topic', (err) => {
            //     //     if (!err) {
            //     //         console.log('Subscribed to test/topic');
            //     //     }
            //     // });
            // });

            // mqttClient.on('message', (topic, message) => {
            //     console.log(`Received message from ${topic}: ${message.toString()}`);
            //     // setReceivedMessage(message.toString());
            // });

            // mqttClient.on('error', (error) => {
            //     console.log('Connection error: ', error);
            // });

            // mqttClient.on('close', () => {
            //     console.log('Connection closed');
            // });
        })();
    }

    useEffect(() => {
        loadData();
    }, [userData]);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'main':
                        return (<LayoutHome />);
                    case 'ticket':
                        return (<LayoutTicket />);
                    case 'setting':
                    case 'subscription':
                    case 'feedback':
                    case 'terms':
                    case 'policy':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
