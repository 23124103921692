import { atom, DefaultValue } from 'recoil';
import CryptoJS from 'crypto-js';

const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (encryptedData, secretKey) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
        console.error('Decryption failed:', e);
        return null;
    }
};

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
        const decryptedValue = decryptData(savedValue, 'FABDROID@cert2024');
        if (decryptedValue != null) {
            setSelf(decryptedValue);
        }
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            const encryptedValue = encryptData(newValue, 'FABDROID@cert2024');
            localStorage.setItem(key, encryptedValue);
        }
    });
};

export const tempDataAtom = atom({
    key: "tempDataAtom",
    default: {},
});

export const modeAtom = atom({
    key: "modeAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('modeAtom'),
    ]
});

export const authAtom = atom({
    key: "authAtom",
    default: 'default',
    effects_UNSTABLE: [
        localStorageEffect('authAtom'),
    ]
});

export const phoneAtom = atom({
    key: "phoneAtom",
    default: '',
});

export const userAtom = atom({
    key: "userAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('userAtom'),
    ]
});

export const tokenAtom = atom({
    key: "tokenAtom",
    default: '',
    effects_UNSTABLE: [
        localStorageEffect('tokenAtom'),
    ]
});

export const userRoleAtom = atom({
    key: "userRoleAtom",
    default: "",
    effects_UNSTABLE: [
        localStorageEffect('userRoleAtom'),
    ]
});

export const meetTypeAtom = atom({
    key: "meetTypeAtom",
    default: '',
});

export const partnerAtom = atom({
    key: "partnerAtom",
    default: '',
});

export const partnerMeetStateAtom = atom({
    key: "partnerMeetStateAtom",
    default: 0,
});

export const partnerMeetConfigAtom = atom({
    key: "partnerMeetConfigAtom",
    default: {},
});

export const walletAtom = atom({
    key: "walletAtom",
    default: { "walletKey": "", "balence": "0" },
});