import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { meetTypeAtom } from '../core/config/atoms';

import LayoutMeetA from 'layouts/LayoutMeetA';
import LayoutMeetB from 'layouts/LayoutMeetB';

export default function PageMeet() {

    const [meetType, setMeetType] = useRecoilState(meetTypeAtom);

    const navigate = useNavigate();

    useEffect(() => {
        if (meetType === '') {
            setMeetType('');
            navigate('/home/main');
        }
    }, []);

    return (
        <div className='h-screen w-full'>
            {(() => {
                switch (meetType) {
                    case '1':
                    case '2':
                    case '3':
                        return (<LayoutMeetA />);
                    case '4':
                        return (<LayoutMeetB />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
