import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../config/atoms';

import { Tv2, Inbox, Settings, LogOut } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getSetting = () => {
        if (page === 'setting' || page === 'subscription' || page === 'feedback' || page === 'terms' || page === 'policy') {
            return true;
        }
        return false;
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        localStorage.clear();
        navigate('/');
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden lg:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('main') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2 brdImg" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    <Tippy content="Interpreter Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "main" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <Tv2 size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Tickets Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'ticket' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('ticket') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'setting' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white text-white hover:text-ascent`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
            <div className='h-[60px] w-full shadow-md bg-prime lg:hidden absolute bottom-0 z-50 flex items-center'>
                <ul className="w-full flex flex-row items-center justify-between px-12">
                    <Tippy content="Interpreter Section" placement='right'>
                        <li className={`cursor-pointer w-10 h-10 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "main" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <Tv2 size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Tickets Section" placement='right'>
                        <li className={`cursor-pointer w-10 h-10 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === 'ticket' ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('ticket') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-10 h-10 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getSetting() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
