import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceProfiles {
    lookupService = new APIServiceLookUp();

    async getAllPartner(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/getAllPartners`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSubscriptionById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subscription/getSubscriptionById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async checkLink(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/checkLink`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}